<template>
    <div class="sports_links">
        <router-link  tag="button" to="/sports_betinfo"> 스포츠</router-link>
        <router-link  to="/leisure_betinfo" tag="button">미니게임</router-link>
<!--        <router-link to="/thirdpartgame_betinfo" tag="button">Tgame365</router-link>-->
<!--        <router-link  to="/honorcasino_betinfo" tag="button">Honor카지노</router-link>-->
        <!--                        <router-link class="bw4" to="/reachcasino_betinfo" tag="button">Reach 카지노</router-link>-->
    </div>
</template>

<script>
    export default {
        name: "BetInfoLinks"
    }
</script>

<style scoped>
    .sports_links button {
        border: 1px solid transparent;
        color: #ffffff;
        width: 49.5%;
    }

</style>